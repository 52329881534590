import { Link } from "react-router-dom";
import { BarraNavTyC } from "../components/BarraNav/BarraNavTyC";

export const Privacidad = () => {
  return (
    <>
      <BarraNavTyC />
      <div className="container" id="Pages">
        <h1>Aviso de Privacidad y Tratamiento de Datos Personales</h1>
        <div className="section-title-divider" />
        <p className="text-justify">
          Queremos darte la bienvenida a la plataforma de servicios jurídicos
          más ágil y confiable. Recuerda siempre que, en <i>Justiexpress</i>,
          somos los más interesados en proteger tus derechos.
          <br />
          <br />
          Con el fin de dar a conocer la existencia de las políticas de
          Tratamiento de datos personales, <i>Justiexpress</i> te invita a leer
          con detenimiento el presente condicionesAviso de Privacidad y
          Tratamiento de Datos Personales (en adelante,{" "}
          <i>Aviso de Privacidad</i>), el cual hace parte integral de nuestros
          Términos y Condiciones de Uso de la Plataforma y de los Documentos
          Fabricados por <i>Justiexpress</i>, el cual puedes consultar{" "}
          <Link id="RouterNavLink" to="/TyC">
            aquí
          </Link>
          .
          <br />
          <br />
          Si tienes alguna pregunta sobre el contenido de este documento,
          comunícate con nosotros a nuestro correo electrónico
          Justiexpress@gmail.com.
        </p>
        <p className="text-justify">
          <b>
            Nombre o razón social y datos de contacto del responsable del
            Tratamiento
          </b>
        </p>
        <p className="text-justify">
          Justiexpress S.A.S. (en adelante, <i>Justiexpress</i>) es una sociedad
          de derecho privado, identificada con NIT No. 901267286-4, domiciliada
          en la ciudad de Bogotá, D.C. y constituida con base en la legislación
          colombiana, que tiene por objeto prestar servicios jurídicos a bajo
          costo, con rapidez, agilidad y en condiciones de alta calidad,
          apalancándose en la revolución de las tecnologías de la información,
          cuyos datos de contacto son los siguientes:
        </p>
        <p className="text-justify">
          - Portal Web: <Link to="/">www.justiexpress.com</Link>
        </p>
        <p className="text-justify">
          - Correo electrónico: justiexpress@gmail.com
        </p>
        <p className="text-justify">
          Los servicios ofrecidos por <i>El Portal</i>, definido como el
          conjunto de los servicios ofrecidos por la{" "}
          <i>Plataforma Web www.justiexpress.com</i>, así como la interacción a
          través de medios digitales tales como nuestras líneas telefónicas y de
          Whatsapp o nuestro correo electrónico justiexpress@gmail.com, se
          prestan en forma onerosa, por cualquiera de los siguientes dos
          mecanismos: <b>i)</b> Mediante fabricación de documentos legales, en
          función de la información suministrada por los Usuarios de{" "}
          <i>El Portal</i> (en adelante, <i>Usuarios</i>) y <b>ii)</b>{" "}
          Directamente, por parte de <i>Justiexpress</i>, por medio de la
          atención de consultas formuladas por los <i>Usuarios</i> a través los
          canales dispuestos en El Portal.
          <br />
          <br />
          En esa medida, la operación de <i>El Portal</i> conlleva a que a{" "}
          <i>Justiexpress</i>, actuando en calidad de Responsable del
          Tratamiento de Datos Personales, almacene en servidores o
          repositorios, propios o de terceros, datos personales recolectados de
          los <i>Usuarios</i>, cuyo Tratamiento se sujetará a lo establecido en
          el presente <i>Aviso de Privacidad</i>, en la Ley 1581 de 2012 y sus
          decretos reglamentarios.
        </p>
        <p className="text-justify">
          <b>Objeto del Aviso de Privacidad</b>
        </p>
        <p className="text-justify">
          El presente <i>Aviso de Privacidad</i> tiene por objeto adoptar la
          Política de Privacidad y Tratamiento de Datos Personales, que permita
          ejercer el derecho constitucional que tienen todas las personas a
          conocer, actualizar y rectificar las informaciones almacenadas sobre
          ellas en bases de datos o archivos bajo custodia de{" "}
          <i>Justiexpress</i>, y los demás derechos, libertades y garantías
          constitucionales a que se refieren los artículos 15 y 20 de la
          Constitución Política, así como la Ley 1581 de 2012.
          <br />
          <br />
          Los datos personales contenidos en bases de datos o archivos bajo
          custodia de <i>Justiexpress</i>, permanecerán allí almacenados durante
          el tiempo que sea necesario para el cumplimiento las finalidades
          mencionadas en el presente <i>Aviso de Privacidad</i>.
        </p>
        <p className="text-justify">
          <b>Definiciones</b>
        </p>
        <p className="text-justify">
          <b>Autorización:</b> Consentimiento previo, expreso e informado del
          Titular para llevar a cabo el Tratamiento de datos personales;
          <br />
          <br />
          <b>Aviso de privacidad:</b> Comunicación verbal o escrita generada por
          el Responsable, dirigida al Titular para el Tratamiento de sus datos
          personales, mediante la cual se le informa acerca de la existencia de
          las políticas de Tratamiento de información que le serán aplicables,
          la forma de acceder a las mismas y las finalidades del Tratamiento que
          se pretende dar a los datos personales.
          <br />
          <br />
          <b>Base de Datos:</b> Conjunto organizado de datos personales que sea
          objeto de Tratamiento;
          <br />
          <br />
          <b>Dato personal:</b> Cualquier información vinculada o que pueda
          asociarse a una o varias personas naturales determinadas o
          determinables;
          <br />
          <br />
          <b>Dato privado:</b> Es el dato que por su naturaleza íntima o
          reservada sólo es relevante para el Titular;
          <br />
          <br />
          <b>Dato público:</b> Es el dato que no sea semiprivado, privado o
          sensible. Son considerados datos públicos, entre otros, los datos
          relativos al estado civil de las personas, a su profesión u oficio y a
          su calidad de comerciante o de servidor público. Por su naturaleza,
          los datos públicos pueden estar contenidos, entre otros, en registros
          públicos, documentos públicos, gacetas y boletines oficiales y
          sentencias judiciales debidamente ejecutoriadas que no estén sometidas
          a reserva;
          <br />
          <br />
          <b>Dato semiprivado:</b> Es semiprivado el dato que no tiene
          naturaleza íntima, reservada, ni pública y cuyo conocimiento o
          divulgación puede interesar no sólo a su Titular sino a cierto sector
          o grupo de personas o a la sociedad en general, como el dato
          financiero y crediticio de actividad comercial o de servicios;
          <br />
          <br />
          <b>Datos sensibles:</b> Se entiende por datos sensibles aquellos que
          afectan la intimidad del Titular o cuyo uso indebido puede generar su
          discriminación, tales como aquellos que revelen el origen racial o
          étnico, la orientación política, las convicciones religiosas o
          filosóficas, la pertenencia a sindicatos, organizaciones sociales, de
          derechos humanos o que promueva intereses de cualquier partido
          político o que garanticen los derechos y garantías de partidos
          políticos de oposición, así como los datos relativos a la salud, a la
          vida sexual, y los datos biométricos.
          <br />
          <br />
          <b>Encargado del Tratamiento:</b> Persona natural o jurídica, pública
          o privada, que por sí misma o en asocio con otros, realice el
          Tratamiento de datos personales por cuenta del Responsable del
          Tratamiento;
          <br />
          <br />
          <b>Responsable del Tratamiento:</b> Persona natural o jurídica,
          pública o privada, que por sí misma o en asocio con otros, decida
          sobre la base de datos y/o el Tratamiento de los datos;
          <br />
          <br />
          <b>Titular:</b> Persona natural cuyos datos personales sean objeto de
          Tratamiento;
          <br />
          <br />
          <b>Tratamiento:</b> Cualquier operación o conjunto de operaciones
          sobre datos personales, tales como la recolección, almacenamiento,
          uso, circulación o supresión;
          <br />
          <br />
          <b>Transferencia:</b> La transferencia de datos tiene lugar cuando el
          Responsable y/o Encargado del Tratamiento de datos personales, ubicado
          en Colombia, envía la información o los datos personales a un
          receptor, que a su vez es Responsable del Tratamiento y se encuentra
          dentro o fuera del país;
          <br />
          <br />
          <b>Transmisión:</b> Tratamiento de datos personales que implica la
          comunicación de los mismos dentro o fuera del territorio de la
          República de Colombia cuando tenga por objeto la realización de un
          Tratamiento por el Encargado por cuenta del Responsable;
          <br />
          <br />
          <b>
            <i>Usuarios</i> de <i>El Portal</i>
          </b>
          : Toda persona que ingrese o solicite un servicio a través de{" "}
          <i>El Portal</i>.
        </p>
        <p className="text-justify">
          <b>Principios</b>
        </p>
        <p className="text-justify">
          En los términos del artículo 4º de la Ley 1581 de 2012, por la cual se
          dictan disposiciones generales para la protección de datos personales,
          el Tratamiento de datos personales atenderá los siguientes principios:
          <br />
          <br />
          <b>Principio de legalidad en materia de Tratamiento de datos:</b> El
          Tratamiento a que se refiere la Ley 1581 de 2012 es una actividad
          reglada que debe sujetarse a lo establecido en ella y en las demás
          disposiciones que la desarrollen;
          <br />
          <br />
          <b>Principio de finalidad:</b> El Tratamiento debe obedecer a una
          finalidad legítima de acuerdo con la Constitución y la Ley, la cual
          debe ser informada al Titular;
          <br />
          <br />
          <b>Principio de libertad:</b> El Tratamiento sólo puede ejercerse con
          el consentimiento, previo, expreso e informado del Titular. Los datos
          personales no podrán ser obtenidos o divulgados sin previa
          autorización, o en ausencia de mandato legal o judicial que releve el
          consentimiento;
          <br />
          <br />
          <b>Principio de veracidad o calidad:</b> La información sujeta a
          Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable
          y comprensible. Se prohíbe el Tratamiento de datos parciales,
          incompletos, fraccionados o que induzcan a error;
          <br />
          <br />
          <b>Principio de transparencia:</b> En el Tratamiento debe garantizarse
          el derecho del Titular a obtener del Responsable del Tratamiento o del
          Encargado del Tratamiento, en cualquier momento y sin restricciones,
          información acerca de la existencia de datos que le conciernan;
          <br />
          <br />
          <b>Principio de acceso y circulación restringida:</b> El Tratamiento
          se sujeta a los límites que se derivan de la naturaleza de los datos
          personales, de las disposiciones de la Ley 1581 de 2012 y la
          Constitución. En este sentido, el Tratamiento sólo podrá hacerse por
          personas autorizadas por el Titular y/o por las personas previstas en
          la Ley 1581 de 2012;
          <br />
          <br />
          Los datos personales, salvo la información pública, no podrán estar
          disponibles en Internet u otros medios de divulgación o comunicación
          masiva, salvo que el acceso sea técnicamente controlable para brindar
          un conocimiento restringido sólo a los Titulares o terceros
          autorizados conforme a la Ley 1581 de 2012;
          <br />
          <br />
          <b>Principio de seguridad:</b> La información sujeta a Tratamiento por
          el Responsable del Tratamiento o Encargado del Tratamiento a que se
          refiere la Ley 1581 de 2012, se deberá manejar con las medidas
          técnicas, humanas y administrativas que sean necesarias para otorgar
          seguridad a los registros evitando su adulteración, pérdida, consulta,
          uso o acceso no autorizado o fraudulento;
          <br />
          <br />
          <b>Principio de confidencialidad:</b> Todas las personas que
          intervengan en el Tratamiento de datos personales que no tengan la
          naturaleza de públicos están obligadas a garantizar la reserva de la
          información, inclusive después de finalizada su relación con alguna de
          las labores que comprende el Tratamiento, pudiendo sólo realizar
          suministro o comunicación de datos personales cuando ello corresponda
          al desarrollo de las actividades autorizadas en la Ley 1581 de 2012 y
          en los términos de la misma.
        </p>
        <p className="text-justify">
          <b>Derechos de los Titulares</b>
        </p>
        <p className="text-justify">
          El Titular de los datos personales tendrá los siguientes derechos, los
          cuales podrán ser ejercidos en cualquier momento a través de los
          mecanismos dispuestos por El Portal, que se detallarán en las
          siguientes secciones:
          <br />
          <br />
          Conocer, actualizar y rectificar sus datos personales frente a los
          Responsables del Tratamiento o Encargados del Tratamiento. Este
          derecho se podrá ejercer, entre otros, frente a datos parciales,
          inexactos, incompletos, fraccionados, que induzcan a error, o aquellos
          cuyo Tratamiento esté expresamente prohibido o no haya sido
          autorizado;
          <br />
          <br />
          Solicitar prueba de la autorización otorgada al Responsable del
          Tratamiento, salvo cuando expresamente se exceptúe como requisito para
          el Tratamiento;
          <br />
          <br />
          Ser informado por el Responsable del Tratamiento o el Encargado del
          Tratamiento, previa solicitud, respecto del uso que le ha dado a sus
          datos personales;
          <br />
          <br />
          Presentar ante la Superintendencia de Industria y Comercio quejas por
          infracciones a lo dispuesto en la Ley 1581 de 2012 y las demás normas
          que la modifiquen, adicionen o complementen;
          <br />
          <br />
          Revocar la autorización y/o solicitar la supresión del dato cuando en
          el Tratamiento no se respeten los principios, derechos y garantías
          constitucionales y legales. La revocatoria y/o supresión procederá
          cuando la Superintendencia de Industria y Comercio haya determinado
          que en el Tratamiento el Responsable o Encargado han incurrido en
          conductas contrarias a la Ley 1581 de 2012 a la Constitución;
          <br />
          <br />
          Acceder en forma gratuita a sus datos personales que hayan sido objeto
          de Tratamiento.
          <br />
          <br />
          Estos derechos podrán ejercerse por las siguientes personas:
          <br />
          <br />
          <b>i)</b> Por el Titular, quien deberá acreditar su identidad en forma
          suficiente por los distintos medios que le ponga a disposición el
          responsable;
          <br />
          <br />
          <b>ii)</b> Por sus causahabientes, quienes deberán acreditar tal
          calidad;
          <br />
          <br />
          <b>iii)</b> Por el representante y/o apoderado del Titular, previa
          acreditación de la representación o apoderamiento.
          <br />
          <br />
          <b>iv)</b> Por estipulación a favor de otro o para otro.
          <br />
          <br />
          Los derechos de los niños, niñas o adolescentes se ejercerán por las
          personas que estén facultadas para representarlos.
        </p>
        <p className="text-justify">
          <b>Autorización y Finalidad</b>
        </p>
        <p className="text-justify">
          Con base en el artículo 9º de la Ley 1581 de 2012, la autorización
          para el Tratamiento de datos personales por parte del Titular sólo
          puede ejercerse con el consentimiento, previo, expreso e informado del
          Titular, lo cual implica que: i) La autorización debe darse antes de
          proceder con el Tratamiento; ii) La autorización debe otorgarse de
          forma inequívoca, clara y específica y; iii) El Titular debe
          comprender claramente para qué serán tratados sus datos personales y
          los efectos que pueden derivarse del Tratamiento de los mismos.
          <br />
          <br />
          La autorización del Tratamiento de datos personales se entenderá
          otorgada conforme se establece en el capítulo primero de los Términos
          y Condiciones de Uso de la Plataforma y de los Documentos Fabricados
          por <i>Justiexpress</i>.
          <br />
          <br />
          Por consiguiente, esta autorización permite a <i>Justiexpress</i>,
          recolectar, transferir, almacenar, usar, circular, suprimir,
          compartir, actualizar y transmitir los datos personales de los
          Usuarios, exclusivamente para las finalidades y usos que a
          continuación se enuncian:
          <br />
          <br />
          <b>1.</b> Para ser ordenados, catalogados, clasificados, divididos o
          separados en las bases de datos de <i>Justiexpress</i>;
          <br />
          <br />
          <b>2.</b> Para desarrollar actividades comerciales, publicitarias y de
          mercadeo, incluyendo, pero sin limitarse ellas, a campañas de
          comunicación, divulgación y promoción u oferta de servicios y
          productos;
          <br />
          <br />
          <b>3.</b> Para que sean sometidos a análisis de inteligencia de
          negocios, investigación de mercados, análisis estadísticos, minería de
          datos y encuestas de calidad de servicio;
          <br />
          <br />
          <b>4.</b> Para entregar la información recolectada a terceros con los
          que <i>Justiexpress</i> contrate el almacenamiento y administración de
          los datos personales, bajo adecuados estándares de seguridad y
          confidencialidad;
          <br />
          <br />
          <b>5.</b> Para la creación y administración de la cuenta del usuario,
          así como para efectuar las respectivas validaciones, verificaciones y
          comprobaciones de los datos suministrados, a la luz de lo establecido
          en los Términos y Condiciones de Uso de la Plataforma y de los
          Documentos Fabricados por <i>Justiexpress</i>;
          <br />
          <br />
          <b>6.</b> Para prestar sus servicios con adecuados estándares de
          calidad, de tal forma que <i>Justiexpress</i> pueda llevar a cabo el
          seguimiento de cada uno de los servicios y mantener contacto con los
          Titulares de los datos;
          <br />
          <br />
          <b>7.</b> Para que puedan ser comunicados y conocidos a los terceros
          proveedores y accionistas de <i>Justiexpress</i>, bajo adecuados
          estándares de seguridad y confidencialidad;
          <br />
          <br />
          <b>8.</b> Para el cumplimiento de las obligaciones legales y
          regulatorias a cargo de <i>Justiexpress</i>, tales como asuntos de
          naturaleza tributaria, comercial y contable;
          <br />
          <br />
          <b>9.</b> Para el control y la prevención del fraude y de lavado de
          activos, incluyendo, pero sin limitarse ellas, para la consulta en
          listas restrictiva y toda la información necesaria requerida para el
          SARLAFT;
          <br />
          <br />
          <b>10.</b> Para que sean objeto de transmisión o trasferencia a los
          terceros con los cuales <i>Justiexpress</i> hayan celebrado contratos,
          cuyo Tratamiento deberá limitarse estrictamente las mismas finalidades
          previstas en el presente documento;
          <br />
          <br />
          <b>11.</b> Las demás finalidades especiales establecidas en el
          presente <i>Aviso de Privacidad</i> en general y, en particular,
          aquellas relacionadas con el tratamiento de datos personales
          sensibles.
        </p>
        <p className="text-justify">
          <b>
            Canales dispuestos por <i>El Portal</i> para la actualización,
            supresión, rectificación de datos personales y revocatoria de la
            autorización y procedimiento
          </b>
        </p>
        <p className="text-justify">
          <i>Justiexpress</i> cuenta con una serie de canales dispuestos para
          que tanto los Titulares de los datos personales, así como cualquier
          otra persona con legitimación para ello, de conformidad con el
          presente Aviso de Privacidad, puedan solicitar la actualización,
          supresión, rectificación de datos personales y revocatoria de la
          autorización. Veamos:
          <br />
          <br />
          Canal electrónico: Dirigiendo su respectiva solicitud al correo
          electrónico <i>Justiexpress@gmail.com</i>.
          <br />
          <br />
          Canal telefónico: Pendiente de habilitación.
          <br />
          <br />
          El contenido de la solicitud deberá reunir los siguientes requisitos:
          <br />
          <br />
          <b>i)</b> Nombre y documento de identificación del Titular;
          <br />
          <br />
          <b>ii)</b> Descripción de los hechos que dan lugar a la solicitud;
          <br />
          <br />
          <b>iii)</b> La petición deberá informar si lo que se pretende es la
          actualización, supresión, rectificación de datos personales o la
          revocatoria de la autorización;
          <br />
          <br />
          <b>iv)</b> Los fundamentos y soportes de los hechos que dan lugar a la
          solicitud.
          <br />
          <br />
          Una vez conocida la solicitud formulada a través de cualquiera de los
          canales dispuestos, <i>Justiexpress</i> procederá a:
          <br />
          <br />
          <b>i)</b> Verificar la legitimación de quien formula la solicitud;
          <br />
          <br />
          <b>ii)</b> Para las solicitudes que tengan que ver con actualización,
          supresión, rectificación de datos personales, <i>Justiexpress</i>{" "}
          recopilará la información que tenga almacenada sobre el Titular y le
          dará curso, de tal forma que la respuesta sea puesta en conocimiento
          del Titular;
          <br />
          <br />
          <b>iii)</b> Cuando la solicitud tenga que ver con revocatoria de la
          autorización, <i>Justiexpress</i> procederá de conformidad y el
          resultado será puesto en conocimiento del Titular.
          <br />
          <br />
          En el evento de que la solicitud no reúna alguno de los criterios
          señalados, <i>Justiexpress</i> se pondrá en contacto con el
          solicitante, con el fin de que aclare o complemente la misma.
        </p>
        <p className="text-justify">
          <b>
            Disposiciones en relación con el Tratamiento de datos personales
            sensibles
          </b>
        </p>
        <p className="text-justify">
          Para el adecuado desarrollo en la prestación de los servicios
          ofertados, <i>El Portal</i> solicitará autorización de los Titulares
          para el Tratamiento de datos personales de naturaleza sensibles,
          entendidos como todos aquellos que pueden afectar su intimidad o cuyo
          uso indebido puede generar su discriminación, tales como aquellos que
          revelen el origen racial o étnico, la orientación política, las
          convicciones religiosas o filosóficas, la pertenencia a sindicatos,
          organizaciones sociales, de derechos humanos o que promueva intereses
          de cualquier partido político o que garanticen los derechos y
          garantías de partidos políticos de oposición, así como los datos
          relativos a la salud, a la vida sexual, y los datos biométricos.
          <br />
          <br />
          En relación con este tipo de datos personales, el artículo 6º de la
          Ley 1581 de 2012, prohíbe el su Tratamiento, excepto cuando el
          Tratamiento se refiera a datos que sean necesarios para el
          reconocimiento, ejercicio o defensa de un derecho en un proceso
          judicial, entre otras excepciones.
          <br />
          <br />
          En tal virtud, como quiera que algunos servicios de <i>
            El Portal
          </i>{" "}
          están destinados justamente para el ejercicio de derechos en procesos
          judiciales, que no requieren de la representación de apoderado
          judicial, <i>Justiexpress</i> recolectará, almacenará y usará de datos
          sensibles asociados a los <i>Usuarios</i>, como titulares de los
          datos, así como aquellos agenciados de los <i>Usuarios</i> que actúen
          en calidad de agentes oficiosos. Cuando concurra esta última calidad
          en los <i>Usuarios</i>, éstos declaran que tienen autorización expresa
          de las personas agenciadas para el tratamiento de sus datos
          personales. En cualquiera de los dos casos, en todo caso,{" "}
          <i>El Portal</i> deja expresa claridad que <i>El Usuario</i>, cuando
          actúa en nombre propio o cuando actúa en calidad de agente oficioso,
          no está obligado a autorizar el Tratamiento de sus datos personales
          sensibles o aquellos de su o sus agenciados.
          <br />
          <br />
          Este <i>Aviso de Privacidad</i> rige a partir del martes, 22 de
          octubre de 2019, sin perjuicio de que pueda ser modificado o
          actualizado, caso en el cual su modificación o actualización será
          puesta a disposición de los <i>Usuarios</i> en <i>El Portal</i>.
        </p>
      </div>
    </>
  );
};
